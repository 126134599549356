import React from 'react'
import { graphql } from 'gatsby';
import Inspiration from '../03 Organisms/ContentBlocksSlice'
import AutoBlockSlice from '../03 Organisms/AutoBlockSlice';
import GalleryFeedSlice from '../03 Organisms/GalleryFeedSlice';

import Layout from './layout'

export const query = graphql`
query LandingPageQuery($uid: String!) {      
    prismic {
        allDestinations {
          edges {
            node {
              _meta {
                tags
                uid
                type
              }
              short_name
              hero_image
              hero_imageSharp {
                childImageSharp {
                  fluid(maxWidth: 1900) {
                      ...GatsbyImageSharpFluid
                  }
                }
              }              
              content
              title
              introduction
            }
          }
        }      
        allLanding_pages(uid: $uid) {
            edges {
                node {
                    title
                    hero_image
                    hero_imageSharp {
                      childImageSharp {
                        fluid(maxWidth: 1900) {
                            ...GatsbyImageSharpFluid
                        }
                      }
                    }
                    introduction                    
                    _meta {
                        uid
                        type
                    }
                    body {
                      ... on PRISMIC_Landing_pageBodyGallery {
                        type
                        label
                        primary {
                          title
                          button_text
                          button_link {
                            ... on PRISMIC_Gallery {
                              title
                              intro
                              body {
                                ... on PRISMIC_GalleryBodyGallery_image {
                                  type
                                  label
                                  fields {
                                    caption
                                    image
                                    imageSharp {
                                      childImageSharp {
                                        fluid(maxWidth: 1900) {
                                            ...GatsbyImageSharpFluid
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }                 
                        ... on PRISMIC_Landing_pageBodyAuto_content_feed {
                          type
                          label
                          primary {
                            heading
                            intro
                          }
                        }                    
                        ... on PRISMIC_Landing_pageBodyInspiration_links {
                            type
                            label
                            primary {
                                section_title
                                link_text
                                link {
                                    ... on PRISMIC_Destination {
                                      hero_imageSharp {
                                        childImageSharp {
                                          fluid(maxWidth: 600) {
                                              ...GatsbyImageSharpFluid
                                          }
                                        }
                                      }
                                      title
                                      _meta {
                                        tags
                                        uid
                                        type
                                      }
                                    }                               
                                    ... on PRISMIC_Landing_page {
                                      hero_imageSharp {
                                        childImageSharp {
                                          fluid(maxWidth: 600) {
                                              ...GatsbyImageSharpFluid
                                          }
                                        }
                                      }
                                      title                                   
                                      _meta {
                                        type
                                        uid
                                      }
                                    }
                                    ... on PRISMIC_News_content {
                                      title
                                      introduction
                                      _meta {
                                        tags
                                        uid
                                        type
                                      }
                                      hero_image
                                      hero_imageSharp {
                                        childImageSharp {
                                          fluid(maxWidth: 600) {
                                              ...GatsbyImageSharpFluid
                                          }
                                        }
                                      }                                      
                                    }
                                    ... on PRISMIC_Inspiration {
                                      title
                                      introduction
                                      destination {
                                        ... on PRISMIC_Landing_page {
                                          hero_imageSharp {
                                            childImageSharp {
                                              fluid(maxWidth: 600) {
                                                  ...GatsbyImageSharpFluid
                                              }
                                            }
                                          }                                                      
                                          title
                                          introduction
                                          _meta {
                                            type
                                            uid
                                          }
                                        }                                        
                                        ... on PRISMIC_Destination {
                                          hero_imageSharp {
                                            childImageSharp {
                                              fluid(maxWidth: 600) {
                                                  ...GatsbyImageSharpFluid
                                              }
                                            }
                                          }                                          
                                          hero_image
                                          title
                                          _meta {
                                            tags
                                            uid
                                            type
                                          }
                                        }
                                      }
                                      _meta {
                                        tags
                                        uid
                                        type
                                      }
                                      hero_image
                                      hero_imageSharp {
                                        childImageSharp {
                                          fluid(maxWidth: 600) {
                                              ...GatsbyImageSharpFluid
                                          }
                                        }
                                      }                                      
                                    }
                                    
                                }                                
                            }
                            fields {
                                link {
                                    ... on PRISMIC_Destination {
                                      hero_image
                                      hero_imageSharp {
                                        childImageSharp {
                                          fluid(maxWidth: 600) {
                                              ...GatsbyImageSharpFluid
                                          }
                                        }
                                      }
                                      title
                                      _meta {
                                        tags
                                        uid
                                        type
                                      }
                                    }                                   
                                    ... on PRISMIC_News_content {
                                        hero_image
                                        hero_imageSharp {
                                          childImageSharp {
                                            fluid(maxWidth: 600) {
                                                ...GatsbyImageSharpFluid
                                            }
                                          }
                                        }
                                        title
                                        introduction
                                        _meta {
                                          tags
                                          type
                                          uid
                                        }
                                      }
                                    ... on PRISMIC_Inspiration {
                                        hero_image
                                        hero_imageSharp {
                                          childImageSharp {
                                            fluid(maxWidth: 600) {
                                                ...GatsbyImageSharpFluid
                                            }
                                          }
                                        }                                      
                                        title
                                        introduction
                                        destination {
                                          ... on PRISMIC_Landing_page {
                                            title
                                            introduction
                                            _meta {
                                              type
                                              uid
                                            }
                                          }                                          
                                          ... on PRISMIC_Destination {
                                            hero_image
                                            title
                                            _meta {
                                              tags
                                              uid
                                              type
                                            }
                                          }
                                        }
                                        _meta {
                                          tags
                                          type
                                          uid
                                        }
                                      }
                                }                                
                                category
                                title1
                                image
                                imageSharp {
                                    childImageSharp {
                                        fluid(maxWidth: 1200, quality: 60) {
                                            ...GatsbyImageSharpFluid
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        allNavigations {
            edges {
                node {
                    primary_navigation_links {
                        link {
                            _linkType
                        }
                    }
                }
            }
        }
        allInspirations {
            edges {
              node {
                destination {
                  ... on PRISMIC_Landing_page {
                    title
                    introduction
                    _meta {
                      type
                      uid
                    }
                  }                                          
                  ... on PRISMIC_Destination {
                    hero_image
                    title
                    _meta {
                      tags
                      uid
                      type
                    }
                  }
                } 
                hero_image
                hero_imageSharp {
                  childImageSharp {
                    fluid(maxWidth: 600) {
                        ...GatsbyImageSharpFluid
                    }
                  }
                }
                title
                introduction
                _meta {
                  type
                  uid
                }
              }
            }
          }
          allNews_contents {
            edges {
              node {
                title
                introduction
                hero_image
                hero_imageSharp {
                  childImageSharp {
                    fluid(maxWidth: 600) {
                        ...GatsbyImageSharpFluid
                    }
                  }
                }
                _meta {
                  tags
                  uid
                  type
                }
              }
            }
          }     
    } 
}
`

const RenderSlices = ({ slices, content, props }) => {
    return slices.map((slice, index) => {
      const res = (() => {
        switch(slice.type) {
          case 'inspiration_links': return (
  
            <section key={index} className="homepage-slice-wrapper link-blocks">
              <Inspiration slice={slice} />
            </section>
  
          )

          case 'auto_content_feed': return (
  
            <section key={index} className="homepage-slice-wrapper auto-feed">
              <AutoBlockSlice props={props} content={content} slice={slice} />
            </section>

          )
          
          case 'gallery': return (
  
            <section key={index} className="homepage-slice-wrapper gallery">
              <GalleryFeedSlice slice={slice} />
            </section>

          )          

          default: return

        }
      }
      )();
      return res;
    })
  }
  
  const RenderIndexBody = ({ home, slices, content, props }) => (
    <React.Fragment>
        {home.body ? 
        <RenderSlices props={props} feed={null} content={content} slices={home.body} />
        : ''}
    </React.Fragment>
  );
  
  const Index = props => {
      const doc = props.data.prismic.allLanding_pages.edges.slice(0,1).pop();
      if(!doc) return null;
      let content = props.data.prismic;
      
      doc.node._meta.uid === 'inspiration' ? 
      content = props.data.prismic.allDestinations.edges
      : 
      
      doc.node._meta.uid === "news-travel-tips" ?
      content = props.data.prismic.allNews_contents.edges
      :
      
      content = props.data.prismic;


    return(
      <Layout data={doc}>
        <RenderIndexBody home={doc.node} content={content} props={props} />
      </Layout>
    )
  }
  
  export default Index;